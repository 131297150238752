import './ThirdSection.css'
import { motion } from 'framer-motion'

const ThirdSection = () => {

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            // y:400,
        },
        animate: {
            opacity: 1,
            // y:0,
        },
    }

    const handleWhatsappMessage=(message)=>{
        let number = "+919300847841";
        var url = "https://wa.me/" + number + "?text=" +  "Hello, I am intreseted in " + message + " course can you please let me know more about it."+ "%0a%0a";
    	window.open(url, '_blank').focus();
    }

    return (<div className="ThirdSection" id='kidsSpecial'>
        <motion.h1
            variants={fadeInAnimationVariants}
            initial="initial"
            whileInView="animate"
            transition={{ delay: 0, duration: 1 }}
            viewport={{
                once: true
            }}
        >Kids Special
        </motion.h1>
        <motion.div
        className='Margine'
            style={{ backgroundColor: "darkorange", height: "2px", margin: "auto", marginBottom: "55px", width: "18%" }}
            variants={fadeInAnimationVariants}
            initial="initial"
            whileInView="animate"
            transition={{ delay: 0, duration: 1 }}
            viewport={{
                once: true
            }}
        ></motion.div>
          <motion.div
        className='phoneMargine'
            style={{ backgroundColor: "darkorange", height: "2px", margin: "auto", marginBottom: "55px", width: "34%" }}
            variants={fadeInAnimationVariants}
            initial="initial"
            whileInView="animate"
            transition={{ delay: 0, duration: 1 }}
            viewport={{
                once: true
            }}
        ></motion.div>

        {/* <div class="container"> */}
            <div class="kidsDiv">

                {/* <motion.div className="col"> */}
                    <motion.div className='kidsImage'
                        variants={fadeInAnimationVariants}
                        initial="initial"
                        whileInView="animate"
                        transition={{ delay: 0, duration: 1 }}
                        viewport={{
                            once: true
                        }}
                    ></motion.div>
                {/* </motion.div> */}
 
                <motion.div className="kidsContentDiv" > 
                    <motion.h4
                        className='courseContentHeading'
                        variants={fadeInAnimationVariants}
                        initial="initial"
                         whileInView="animate"
                        transition={{ delay: 0, duration: 1 }}
                        viewport={{
                            once: true
                        }}
                    >Things covered in this course :-</motion.h4>
                    <motion.h1 variants={fadeInAnimationVariants}
                        initial="initial"
                        whileInView="animate"
                        transition={{ delay: 0, duration: 2 }}
                        viewport={{
                            once: true
                        }}
                        id='kidsContent'>Drawing, Pencil Color and Shading, Over Coating, Imaginary Work</motion.h1>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <motion.button className='btn btn-outline-dark'
                            initial="initial"
                            whileInView="animate"
                            transition={{ delay: 1, duration: 2 }}
                            viewport={{
                                once: true
                            }}
                            onClick={()=>handleWhatsappMessage("Kids Special")}
                        >Contact For Course  <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i></motion.button>
                        <motion.p
                            className='seatsLeft'
                        >Few seats Left</motion.p> 
                    </div>
                </motion.div>
            </div>
        {/* </div> */}
  
        <div class="custom-shape-divider-bottom-1716275206">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
            </svg>
        </div>

    </div>)
}

export default ThirdSection;