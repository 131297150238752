import './Fotter.css'
import logo from'../images/logo-removebg-preview.png'

const Fotter = () =>{
    return(<div className='Fotter' id='fotter'>
        <div style={{width:"100vw",display:"flex",justifyContent:"center"}}>
        <img src={logo} className='footerLogo'></img>
        </div>
        <h1>Contact Us</h1>
        <div className='Margine' style={{ backgroundColor: "darkorange", height: "2px", margin: "auto", marginBottom: "40px", width: "15%" }}></div>
        <div className='phoneMargine' style={{ backgroundColor: "darkorange", height: "2px", margin: "auto", marginBottom: "40px", width: "35%" }}></div>
            <div className='fotterContainer'>
                <div className='insideFotterContainer'>              
                      <div className='col'>
                    <h4>Get In Touch</h4>
                     <a href="mailto:anjanvivek06@gmail.com" className='number'
                     >anjanvivek06@gmail.com</a>   
                     <a href={`tel:${9300847841}`}className='number'>9300847841</a>
                     <a href={`tel:${9300847841}`
                     } className='number'>7489148585</a>       
                </div>
                <div className='col' id='centerDiv'>
                    <h4>Social Media</h4>
                    <div className='socialLinks'>
                     <a href='https://www.instagram.com/anjanvivek_art/' target='_blank'><i class="fa-brands fa-instagram"></i></a>
                   <a href='https://www.facebook.com/people/Vivek-Verma/pfbid024r3uc1k2PX2LeEKrNkEegv7SsBijSScHfLLcba3rnAcx6YCeGeAWz5Go3Pos88zal/?mibextid=qi2Omg&rdid=6EpIbKyrXriQ9jkg&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fh5T1V9k1ackJdnaX%2F%3Fmibextid%3Dqi2Omg'  target='_blank'><i class="fa-brands fa-square-facebook"></i></a>
                   <a href='https://x.com/anjaanVivek'  target='_blank'><i class="fa-brands fa-square-x-twitter"></i></a>
                    </div>
                </div>
                <div className='col' id="address">
                    <h4>Where we are</h4>
                    <p> C-519 Shahpura C Sector in Front Of Bansal Hospital
                        ,Bhopal (M.P)
                    </p>
                </div>
            </div>
            <div>
            <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1007.3748663183992!2d77.42110805756441!3d23.1972013164323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c43002ed35ceb%3A0x3d62efe3ea96e6d2!2sPractical%20Art%20Classes!5e0!3m2!1sen!2sin!4v1718249857655!5m2!1sen!2sin" 
            width="600" height="450"
            style={{width:"100%",height:"200px",margin:"auto"}}>
            </iframe>
            </div>
        </div>    
    </div>)
}
  
export default Fotter;