import { useEffect } from 'react';
import './TopSection.css'
import { motion, useViewportScroll ,useTransform} from 'framer-motion';

const TopSection = () => {

    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      section.scrollIntoView({ behavior: 'smooth' });
    };   

    return (<div className='topDiv'>
      {/* <div className='topBackground'></div> */}
        <h1>
          The Art Of Making One's Self 
            More Alive and Expressive
        </h1>
        <motion.p 
          className='since'
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{delay:1,duration:2}}
          
          >Since <span>1996</span>
          </motion.p>
        <div className='topBtns'>
        <button style={{marginTop:"20px"}}className='btn btn-outline-success' onClick={(e)=>{scrollToSection('course')}}>Explore Cources</button>
        <button style={{marginTop:"20px",marginLeft:"20px"}}className='btn btn-outline-primary' onClick={(e)=>{scrollToSection('kidsSpecial')}}>Kids Special</button>
        </div>
        <h4 id='levelUp'>Let's Level Up </h4>
  
        <motion.div className='photo1'
           whileHover={{ top:40, rotate: -10 }}
        >
        </motion.div>
        <motion.div className='photo2' 
         whileHover={{ top:35, rotate: 10 }}>
          </motion.div> 
        <motion.div className='photoPhone1'
        whileTap={{ rotate:6}}
        ></motion.div> 
         <motion.div 
        className='photoPhone2' whileTap={{ rotate:6}}
        ></motion.div> 
        </div>
        )}
export default TopSection;