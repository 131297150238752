import { useState } from 'react';
import './Navbar.css'
import { motion, useViewportScroll ,useTransform} from 'framer-motion';
import logo from '../images/logo-removebg-preview.png'

const Navbar  =()=>{

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
      }; 

    const [navopen,setNavopen] = useState(false);
   
    return (<div className="navbar" style={{height:navopen&&"100vh"}}>
        <div className='logoDiv'>
          <img id='logoImg' src={logo}></img>
        <h4 id='logo'>Practical Art Classes</h4>
        </div> 
        <div id='navs'>
            <h5 onClick={(e)=>{scrollToSection('course')}}>Courses</h5>
            <h5 onClick={(e)=>{scrollToSection('kidsSpecial')}}>Kids Special</h5>
            <h5 onClick={(e)=>{scrollToSection('aboutArtist')}}>About Artist</h5>
            <h5 onClick={(e)=>{scrollToSection('workDoneInClass')}}>Work Done In Class</h5>
            <h5 onClick={(e)=>{scrollToSection('fotter')}}>Contanct Us</h5>
        </div>  
        <div className='menuBar' onClick={(e)=>{setNavopen(!navopen)}}>
            <div className={navopen && "upperBar"} 
                style={{height:"1px",width:"15px",
                        backgroundColor:"black"}}>
            </div> 
            <div className={navopen && "lowerBar"} 
                style={{height:"1px",width:"15px",
                backgroundColor:"black",marginTop:"5px"}}>
                </div>
        </div>
      <div 
        style={{display:!navopen && "none",transition:"all 0.5s easy-in-out"}} 
        className='navFull'>
      <h4 onClick={(e)=>{
        scrollToSection('course')
        setNavopen(false)
      }}>Course</h4>
      <h4 onClick={(e)=>{
        scrollToSection('kidsSpecial')
        setNavopen(false)
      }}>kidsSpecial</h4>
      <h4 onClick={(e)=>{
        scrollToSection('aboutArtist')
        setNavopen(false)
      }}>About Artist</h4>
      <h4 onClick={(e)=>{
        scrollToSection('workDoneInClass')
        setNavopen(false)
      }}>Work Done In Class</h4>
      <h4 onClick={(e)=>{
        scrollToSection('fotter')
        setNavopen(false)
      }}>Contanct Us</h4>
    </div>
    </div>)
}

export default Navbar