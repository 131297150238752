import logo from './logo.svg';
import './App.css';
import LoadingPage from './pages/LoadingPage';
import HomePage from './pages/HomePage';
import { useState } from 'react';

function App() {

  const [continueBtn,setContinueBtn] = useState(false);
 
  return (
    <div className="App" style={{overflowX:"hidden"}}>
      {!continueBtn?
      <LoadingPage continueBtn={continueBtn} setContinueBtn={setContinueBtn}/>
        :<HomePage/>
    }
      
    </div>
  );
}

export default App;
