import { animate, motion } from 'framer-motion';
import './SecondSection.css'
import { useState } from 'react';


const SecondSection = () => {

    const fadeInAnimationVariants = {
        initial: {
            opacity: 0,
            // y:400,
        },
        animate: {
            opacity: 1,
            // y:0,
        },
    }

    const handleWhatsappMessage = (message) => {
        let number = "+919300847841";
        var url = "https://wa.me/" + number + "?text=" + "Hello, I am intreseted in " + message + " course can you please let me know more about it." + "%0a%0a";
        window.open(url, '_blank').focus();
    }

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
    };

    const [moreCourses, setMoreCourses] = useState(false);

    return (<motion.div className="secondSection" id='course'>
        <motion.h1
            variants={fadeInAnimationVariants}
            initial="initial"
            whileInView="animate"
            transition={{ delay: 0, duration: 1 }}
            viewport={{
                once: true
            }}
        >Courses For Adults.
        </motion.h1>
        <div 
        className='Margine'
        style={{ 
            backgroundColor: "darkorange", 
            height: "2px", 
            margin: "auto", 
            marginBottom: "55px", 
            width: "28%" }}></div>
            <div 
        className='phoneMargine'
        style={{ 
            backgroundColor: "darkorange", 
            height: "2px", 
            margin: "auto", 
            marginBottom: "55px", 
            width: "58%" }}></div>
        <div
            className={!moreCourses ? 'showMore' : 'showLess'}
        >
            <div class="row">

                <motion.div class="col">
                    <motion.div
                        className='card'
                        variants={fadeInAnimationVariants}
                        initial="initial"
                        whileInView="animate"
                        transition={{ delay: 0, duration: 1 }}
                        viewport={{
                            once: true
                        }}
                    >
                        <div className='cardImg'>
                            <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Drawing and Pencil Color")}>Interested
                                <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
                            </button>
                        </div>
                        <motion.div
                            className='courseContent'
                            variants={fadeInAnimationVariants}
                            initial="initial"
                            whileInView="animate"
                            transition={{ delay: 0, duration: 3 }}
                            viewport={{
                                once: true
                            }}
                        >
                            <h4>Drawing and Pencil Color</h4>
                            <p>Topics Covered</p>
                            <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
                                <button className='btn topicBtns '>Drawing</button>
                                <button className='btn topicBtns '>Pencil Color</button>
                                <button className='btn topicBtns '>Shading</button>
                                <button className='btn topicBtns '>Overcoating</button>
                                <button className='btn topicBtns '>Imaginative work</button>

                            </div>
                        </motion.div>
                        <div className='pricing'>
                            <p><span>By</span> Vivek Kumar Verma</p>
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <p id='price'>₹1499</p>
                                <p id='priceR' style={{ marginLeft: "5px" }}>₹1899</p>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
                {/* <button>Intrested</button> */}
                <motion.div
                    className="col"
                    variants={fadeInAnimationVariants}
                    initial="initial"
                    whileInView="animate"
                    transition={{ delay: 0, duration: 1.2 }}
                    viewport={{
                        once: true
                    }}
                >
                    <motion.div
                        className='card'
                        variants={fadeInAnimationVariants}
                        initial="initial"
                        whileInView="animate"
                        transition={{ delay: 0, duration: 1 }}
                        viewport={{
                            once: true
                        }}
                    >
                        <div className='cardImg2'>
                            <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Water Color")}>Interested
                                <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
                            </button>
                        </div>
                        <motion.div
                            className='courseContent'
                            variants={fadeInAnimationVariants}
                            initial="initial"
                            whileInView="animate"
                            transition={{ delay: 0, duration: 3 }}
                            viewport={{
                                once: true
                            }}
                        >
                            <h4>Water Color</h4>
                            <p>Topics Covered</p>
                            <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
                                <button className='btn topicBtns '>Drawing</button>
                                <button className='btn topicBtns '>Wash Method</button>
                                <button className='btn topicBtns '>Color Mixing</button>
                                <button className='btn topicBtns '>Over Coating</button>
                            </div>
                        </motion.div>
                        <div className='pricing'>
                            <p><span>By</span> Vivek Kumar Verma</p>
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <p id='price'>₹1799</p>
                                <p id='priceR' style={{ marginLeft: "5px" }}>₹2199</p>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
                <motion.div
                    className="col"
                    variants={fadeInAnimationVariants}
                    initial="initial"
                    whileInView="animate"
                    transition={{ delay: 0, duration: 1.4 }}
                    viewport={{
                        once: true
                    }}
                >
                    <motion.div
                        className='card'
                        variants={fadeInAnimationVariants}
                        initial="initial"
                        whileInView="animate"
                        transition={{ delay: 0, duration: 1 }}
                        viewport={{
                            once: true
                        }}
                    >
                        <div className='cardImg3'>
                            <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Acrylic Color")}>Interested
                                <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
                            </button>
                        </div>
                        <motion.div
                            className='courseContent'
                            variants={fadeInAnimationVariants}
                            initial="initial"
                            whileInView="animate"
                            transition={{ delay: 0, duration: 3 }}
                            viewport={{
                                once: true
                            }}
                        >
                            <h4>Acrylic Color</h4>
                            <p>Topics Covered</p>
                            <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
                                <button className='btn topicBtns '>Drawing</button>
                                <button className='btn topicBtns '>Coloring On Oil Sheet</button>
                                <button className='btn topicBtns '>Coloring On Canvas</button>
                            </div>
                        </motion.div>
                        <div className='pricing'>
                            <p><span>By</span> Vivek Kumar Verma</p>
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <p id='price'>₹1999</p>
                                <p id='priceR' style={{ marginLeft: "5px" }}>₹2299</p>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>

            </div> 

            <div className='movingHeading'>
                <h1>Emphasis on practical, hands-on exercises, classes led by experienced and one of the best artist in the city. Ensures individual attention and personalized instruction, There will be three classes per week.Course Duration is of a month.</h1>
                <h1 style={{ marginLeft: "50px" }}>Emphasis on practical, hands-on exercises, classes led by experienced and one of the best artist in the city. Ensures individual attention and personalized instruction, There will be three classes per week.Course Duration is of a month.</h1>
            </div>

            {moreCourses ?
                <div className='moreCoursesDiv' >
                    <div class="row">
                        <div class="col">
                            <motion.div
                                className='card'
                                variants={fadeInAnimationVariants}
                                initial="initial"
                                whileInView="animate"
                                transition={{ delay: 0, duration: 1 }}
                                viewport={{
                                    once: true
                                }}
                            >
                                <div className='cardImg4'>
                                    <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Figure Anatomy")}>Interested
                                        <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
                                    </button>
                                </div>
                                <motion.div
                                    className='courseContent'
                                    variants={fadeInAnimationVariants}
                                    initial="initial"
                                    whileInView="animate"
                                    transition={{ delay: 0, duration: 3 }}
                                    viewport={{
                                        once: true
                                    }}
                                >
                                    <h4>Figure Anatomy</h4>
                                    <p>Topics Covered</p>
                                    <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
                                        <button className='btn topicBtns '>Drawing</button>
                                        {/* <button className='btn topicBtns '>Illustration Work</button> */}
                                        <button className='btn topicBtns '>Story work</button>
                                    </div>
                                </motion.div>
                                <div className='pricing'>
                                    <p><span>By</span> Vivek Kumar Verma</p>
                                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                                        <p id='price'>₹1999</p>
                                        <p id='priceR' style={{ marginLeft: "5px" }}>₹2299</p>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                        <div class="col">

                            <motion.div
                                className='card'
                                variants={fadeInAnimationVariants}
                                initial="initial"
                                whileInView="animate"
                                transition={{ delay: 0, duration: 1 }}
                                viewport={{
                                    once: true
                                }}
                            >
                                <div className='cardImg5'>
                                    <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Oil Painting")}>Interested
                                        <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
                                    </button>
                                </div>
                                <motion.div
                                    className='courseContent'
                                    variants={fadeInAnimationVariants}
                                    initial="initial"
                                    whileInView="animate"
                                    transition={{ delay: 0, duration: 3 }}
                                    viewport={{
                                        once: true
                                    }}
                                >
                                    <h4>Oil Painting</h4>
                                    <p>Topics Covered</p>
                                    <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
                                        <button className='btn topicBtns '>Drawing</button>
                                        <button className='btn topicBtns '>On Oil Sheet</button>
                                        <button className='btn topicBtns '>Painting On Canvas</button>
                                        <button className='btn topicBtns '>Color Mixing</button>
                                        <button className='btn topicBtns '>Strok Work</button>

                                    </div>
                                </motion.div>
                                <div className='pricing'>
                                    <p><span>By</span> Vivek Kumar Verma</p>
                                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                                        <p id='price'>₹2999</p>
                                        <p id='priceR' style={{ marginLeft: "5px" }}>₹3299</p>
                                    </div>
                                </div>
                            </motion.div>
                        </div><div className='col'>
                            <motion.div
                                className="col"
                                variants={fadeInAnimationVariants}
                                initial="initial"
                                whileInView="animate"
                                transition={{ delay: 0, duration: 1.8 }}
                                viewport={{
                                    once: true
                                }}
                            >
                                <motion.div
                                    className='card'
                                    variants={fadeInAnimationVariants}
                                    initial="initial"
                                    whileInView="animate"
                                    transition={{ delay: 0, duration: 1 }}
                                    viewport={{
                                        once: true
                                    }}
                                >
                                    <div className='cardImg6'>
                                        <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Portrait")}>Interested
                                            <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
                                        </button>
                                    </div>
                                    <motion.div
                                        className='courseContent'
                                        variants={fadeInAnimationVariants}
                                        initial="initial"
                                        whileInView="animate"
                                        transition={{ delay: 0, duration: 3 }}
                                        viewport={{
                                            once: true
                                        }}
                                    >
                                        <h4>Portrait</h4>
                                        <p>Topics Covered</p>
                                        <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
                                            <button className='btn topicBtns '>Drawing</button>
                                            <button className='btn topicBtns '>Sketching</button>
                                            <button className='btn topicBtns '>Water Color</button>
                                            <button className='btn topicBtns '>Oil Color</button>

                                        </div>
                                    </motion.div>
                                    <div className='pricing'>
                                        <p><span>By</span> Vivek Kumar Verma</p>
                                        <div style={{ display: 'flex', justifyContent: 'right' }}>
                                            <p id='price'>₹2999</p>
                                            <p id='priceR' style={{ marginLeft: "5px" }}>₹3299</p>
                                        </div>
                                    </div>
                                </motion.div>
                            </motion.div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">

                            <motion.div
                                className='card'
                                variants={fadeInAnimationVariants}
                                initial="initial"
                                whileInView="animate"
                                transition={{ delay: 0, duration: 1 }}
                                viewport={{
                                    once: true
                                }}
                            >
                                <div className='cardImg7'>
                                    <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Landscape")}>Interested
                                        <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
                                    </button>
                                </div>
                                <motion.div
                                    className='courseContent'
                                    variants={fadeInAnimationVariants}
                                    initial="initial"
                                    whileInView="animate"
                                    transition={{ delay: 0, duration: 3 }}
                                    viewport={{
                                        once: true
                                    }}
                                >
                                    <h4>Landscape</h4>
                                    <p>Topics Covered</p>
                                    <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
                                        <button className='btn topicBtns '>Drawing</button>
                                        <button className='btn topicBtns '>Color</button>
                                        {/* <button className='btn topicBtns '>Shading</button> */}
                                        {/* <button className='btn topicBtns '>Overcoating</button> */}
                                        <button className='btn topicBtns '>Oil Color</button>

                                    </div>
                                </motion.div>
                                <div className='pricing'>
                                    <p><span>By</span> Vivek Kumar Verma</p>
                                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                                        <p id='price'>₹2799</p>
                                        <p id='priceR' style={{ marginLeft: "5px" }}>₹3199</p>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                        <motion.div
                            className="col"
                            variants={fadeInAnimationVariants}
                            initial="initial"
                            whileInView="animate"
                            transition={{ delay: 0, duration: 1 }}
                            viewport={{
                                once: true
                            }}
                        >
                            <motion.div
                                className='card'
                                variants={fadeInAnimationVariants}
                                initial="initial"
                                whileInView="animate"
                                transition={{ delay: 0, duration: 1 }}
                                viewport={{
                                    once: true
                                }}
                            >
                                <div className='cardImg8'>
                                    <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Still Life")}>Interested
                                        <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
                                    </button>
                                </div>
                                <motion.div
                                    className='courseContent'
                                    variants={fadeInAnimationVariants}
                                    initial="initial"
                                    whileInView="animate"
                                    transition={{ delay: 0, duration: 3 }}
                                    viewport={{
                                        once: true
                                    }}
                                >
                                    <h4>Still Life</h4>
                                    <p>Topics Covered</p>
                                    <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
                                        <button className='btn topicBtns '>Drawing</button>
                                        <button className='btn topicBtns '>Pencil Color</button>
                                        <button className='btn topicBtns '>Proportion</button>

                                    </div>
                                </motion.div>
                                <div className='pricing'>
                                    <p><span>By</span> Vivek Kumar Verma</p>
                                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                                        <p id='price'>₹1799</p>
                                        <p id='priceR' style={{ marginLeft: "5px" }}>₹2199</p>
                                    </div>
                                </div>
                            </motion.div>
                        </motion.div>
                        <motion.div
                            className="col"
                            variants={fadeInAnimationVariants}
                            initial="initial"
                            whileInView="animate"
                            transition={{ delay: 0, duration: 1.4 }}
                            viewport={{
                                once: true
                            }}
                        >
                            <motion.div
                                className='card'
                                variants={fadeInAnimationVariants}
                                initial="initial"
                                whileInView="animate"
                                transition={{ delay: 0, duration: 1 }}
                                viewport={{
                                    once: true
                                }}
                            >
                                <div className='cardImg9'>
                                    <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Designing")}>Interested
                                        <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
                                    </button>
                                </div>
                                <motion.div
                                    className='courseContent'
                                    variants={fadeInAnimationVariants}
                                    initial="initial"
                                    whileInView="animate"
                                    transition={{ delay: 0, duration: 3 }}
                                    viewport={{
                                        once: true
                                    }}
                                >
                                    <h4>Designing</h4>
                                    <p>Topics Covered</p>
                                    <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
                                        <button className='btn topicBtns '>Drawing</button>
                                        <button className='btn topicBtns '>Pencil Color</button>
                                        <button className='btn topicBtns '>Logo</button>
                                        <button className='btn topicBtns '>jewellery</button>
                                         <button className='btn topicBtns '>Dress</button>

                                    </div>
                                </motion.div>
                                <div className='pricing'>
                                    <p><span>By</span> Vivek Kumar Verma</p>
                                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                                        <p id='price'>₹2199</p>
                                        <p id='priceR' style={{ marginLeft: "5px" }}>₹2599</p>
                                    </div>
                                </div>
                            </motion.div>
                        </motion.div>
                    </div>
                    <div class="row">
                        <div class="col">

                            <motion.div
                                className='card'
                                variants={fadeInAnimationVariants}
                                initial="initial"
                                whileInView="animate"
                                transition={{ delay: 0, duration: 1 }}
                                viewport={{
                                    once: true
                                }}
                            >
                                <div className='cardImg10'>
                                    <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Mural Work")}>Interested
                                        <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
                                    </button>
                                </div>
                                <motion.div
                                    className='courseContent'
                                    variants={fadeInAnimationVariants}
                                    initial="initial"
                                    whileInView="animate"
                                    transition={{ delay: 0, duration: 3 }}
                                    viewport={{
                                        once: true
                                    }}
                                >
                                    <h4>Mural Work</h4>
                                    <p>Topics Covered</p>
                                    <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
                                        <button className='btn topicBtns '>Drawing</button>
                                        <button className='btn topicBtns '>Color</button>
                                        <button className='btn topicBtns'>Paper Mache</button>

                                    </div>
                                </motion.div>
                                <div className='pricing'>
                                    <p><span>By</span> Vivek Kumar Verma</p>
                                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                                        <p id='price'>₹3499</p>
                                        <p id='priceR' style={{ marginLeft: "5px" }}>₹3599</p>
                                    </div>

                                </div>
                            </motion.div>
                        </div>
                    
                        <div class="col">

<motion.div
    className='card'
    variants={fadeInAnimationVariants}
    initial="initial"
    whileInView="animate"
    transition={{ delay: 0, duration: 1 }}
    viewport={{
        once: true
    }}
>
    <div className='cardImg11'>
        <button className='btn btn-dark interestedBtn' onClick={() => handleWhatsappMessage("Mural Work")}>Interested
            <i class="fa-brands fa-whatsapp" style={{ marginLeft: "10px" }}></i>
        </button>
    </div>
    <motion.div
        className='courseContent'
        variants={fadeInAnimationVariants}
        initial="initial"
        whileInView="animate"
        transition={{ delay: 0, duration: 3 }}
        viewport={{
            once: true
        }}
    >
        <h4>Illustration</h4>
        <p>Topics Covered</p>
        <div style={{ width: "100%", display: "flex", gap: "4px", flexWrap: "wrap", marginTop: "-10px" }}>
            <button className='btn topicBtns '>Drawing</button>
            <button className='btn topicBtns '>Color of your choice</button>
            <button className='btn topicBtns '>Shading</button>
            <button className='btn topicBtns '>Overcoating</button>
            <button className='btn topicBtns '>Imaginative work</button>
            <button className='btn topicBtns '>Story work</button>
            

        </div>
    </motion.div>
    <div className='pricing'>
        <p><span>By</span> Vivek Kumar Verma</p>
        <div style={{ display: 'flex', justifyContent: 'right' }}>
            <p id='price'>₹2999</p>
            <p id='priceR' style={{ marginLeft: "5px" }}>₹3299</p>
        </div>

    </div>
</motion.div>
</div>

                    </div>
                </div>
                : null}

        </div>


        <div style={{ display: "flex", justifyContent: "center", textAlign: "center", margin: "14px", marginTop: !moreCourses && "100px" }}>
            <motion.button
                variants={fadeInAnimationVariants}
                initial="initial"
                whileInView="animate"
                transition={{ delay: 0, duration: 1 }}
                viewport={{
                    once: true
                }}
                className='btn btn-outline-light moreCourses' onClick={(e) => {
                    setMoreCourses(!moreCourses)
                    moreCourses && scrollToSection('course')
                }}>{moreCourses ? "Show Less" : "More Courses"}                    </motion.button>
        </div>
        <p className='see'>It's more than you see</p>
        {/* </div> */}

        <div class="custom-shape-divider-top-1716263409">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
            </svg>
        </div>

        <div class="custom-shape-divider-bottom-1716266480">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
            </svg>
        </div>

    </motion.div>

    )
}

export default SecondSection;