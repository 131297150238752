import Fotter from '../components/Fotter'
import FourthSection from '../components/FourthSection'
import Myth from '../components/Myth'
import Navbar from '../components/Navbar'
import SecondSection from '../components/SecondSection'
import ThirdSection from '../components/ThirdSection'
import TopSection from '../components/TopSection'

const HomePage = ()=>{

    const handleWhatsappMessage=()=>{
        let number = "+919300847841";
        let name = "name"
        let email = "email"
        let message = "message"
        var url = "https://wa.me/" + number ;

    	window.open(url, '_blank').focus();
      
    }

    return (<div >
        <Navbar/>
        <Myth/>
        <TopSection/>
        <SecondSection/>
     <ThirdSection/>
     <FourthSection/>
     <Fotter/>

      


     <div class="modal fade" id="contactModel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
     <div class="modal-dialog">
     <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Get In Touch</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <a href={`tel:${9300847841}`}><button className='btn btn-outline-dark'>Make a Call</button></a>
        <button className='btn btn-outline-success ms-2' onClick={handleWhatsappMessage}>Whatsapp Message</button>
      </div>
    </div>
  </div>
</div>
     <button className='btn btn-success getInTouch' data-bs-toggle="modal" data-bs-target="#contactModel">Get In Touch</button>
    </div>

)
}

export default HomePage