import React, { useState } from "react";
import { motion } from "framer-motion";
import './LoadingPage.css';

const LoadingPage = ({continueBtn,setContinueBtn}) => {

    // const [continueBtn, setContinueBtn] = useState(false);

    return (
      <>
      
      <div>

        <motion.div
            className="firstDiv"
        >
        </motion.div>
        
        <motion.div 
       className="textDiv"
       initial={{ y: "-100vh" }}
       animate={{ y: 0 }}
       transition={{ duration: 1, delay: 1 }}
       > 
        <motion.h1
        className="name"
        initial={{ display: 'block' }}
        animate={{ display: 'none' }}
        transition={{ duration: 1, delay: 3 }}
        >
            Introducing Bhopal's One Of The Best Art Class
        </motion.h1>

        <motion.h1
        className="name"
        initial={{ opacity: 0 ,display:'none'}}
        animate={{ opacity: 1 ,display:'block'}}
        transition={{ duration: 2, delay: 5 }}
        >
            Practical Art Classes 
        </motion.h1>
        <motion.button 
        className="btn btn-success continueBtn"
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:1,delay:6}}
        onClick={()=>setContinueBtn(true)}
        >
            Continue
        </motion.button>
        </motion.div>
        </div>
        </>
    );
};

export default LoadingPage;