import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import './Myth.css'

const Myth =()=>{

    const divRef = useRef(null);

    const handleClose = ()=>{
        divRef.current.style.display='none';
    }
    
 useEffect(()=>{
     setTimeout(()=>{
         divRef.current.style.display='none';
     },60000)
 },[])

    return (
        <motion.div 
            ref={divRef} 
            className='parentMythDiv'
            initial={{scale:0}}
            animate={{scale:1}}
            transition={{duration:1,delay:4}}
            >        
             <div className="mythDiv">
                <h1>Here Are Some Facts You Should Know Before Opting Any Art Classes</h1>
                <i class="fa fa-times cross" aria-hidden="true" onClick={handleClose}></i>       
                    <h4>Learning art needs hands on practice, choosing offline classes will be better then online classes</h4>
                    <h4>Sitting in a class with hundreds of students can make learning difficult, it's better to be in a class with a limited number of students.</h4>
                    <h4>Clearing basic concepts is a must before directly jumping into a detailed painting or sketch.</h4>
                    <h4>Yes, there is a prerequisite for learning art, and that’s a hunger for learning it.</h4>
                <h2>Practical Art Classes</h2>
                <button onClick={handleClose} className='btn btn-outline-dark closebtn'>Close</button>
        </div>
        </motion.div>
    )
}

export default Myth;