import './FourthSection.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import studentMain from '../images/student2.jpg'
import CountUp from 'react-countup';
import { useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';

const FourthSection = () => {

  const [counterOn,setCounterOn] = useState(false);

  const fadeInAnimationVariants={
    initial:{
        opacity:0,
    },
    animate:{
        opacity:1,
    },
}

  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 3,
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }; 

  let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9,10] 
  let arr1 = [{id:1,title:"Bal Hanuman",content:"Modern Art Oil Painting"},{id:2,title:"Ardhnareshwar",content:"Modern Art Acrylic Painting"},{id:3,title:"Portrait",content:"Realistic Work, Oil On Canvas"},{id:4,title:"The Eravat",content:"Modern Art Oil Painting"},{id:5,title:"Utsav",content:"Modern Art Oil Painting"},{id:6,title:"Lord Ganesha",content:"Mural Work Give Antique Look"},{id:7,title:"Saras Slupture",content:"Saras Sculpture Made For Regional Museum"},{id:8,title:"Flower Ceiling Lamp",content:"Made Up From Thermocol For Ceiling Decoration"},{id:9,title:"Snake Holding Gloab",content:"Best Out Of Waste Demonstration in College"},{id:10,title:"Body Paint Art",content:"Done Body Paint Art For Peta In Various Cities Of India"},{id:11,title:"Illustration",content:"Illustration on magazine for Eklavya"},{id:12,title:"Illustration",content:"Illustration on magazine for Samavesh Ka Prakashan"}]

  return (<div className='FourthSection' id='aboutArtist'>
    <div className='row'>
      <div className='col artistpic'>
        <motion.div className='ArtistPhoto'
                 variants={fadeInAnimationVariants}
                 initial="initial"
                 whileInView="animate"
                 transition={{delay:0,duration:2}}
                 viewport={{
                     once:true
                 }}
        >
        </motion.div>
      </div>
      <motion.div className='col aboutArtist'
               variants={fadeInAnimationVariants}
               initial="initial"
               whileInView="animate"
               transition={{delay:0,duration:2}}
               viewport={{
                   once:true
               }}
      > 
        <h1 id='aboutArtisth'>Vivek Kumar Verma <span> (Anjaan)</span></h1>
        <h4>FREELANCE ARTIST</h4>
        <p>"Abhyas Hi Kala Hai, Kala Hi Abhyas !"</p>
      </motion.div>
    </div>  

    <motion.h1
     style={{ marginBottom: "10px", marginTop: "50px" }} 
     className='creationHeading'>
      Creations
    </motion.h1>
    <motion.div 
    className='Margine'
    style={{ backgroundColor: "darkorange", 
             height: "2px", 
             margin: "auto", 
             marginBottom: "15px", 
             width: "14%" }}>
    </motion.div>
    <motion.div 
    className='phoneMargine'
    style={{ backgroundColor: "darkorange", 
             height: "2px", 
             margin: "auto", 
             marginBottom: "15px", 
             width: "34%" }}>
    </motion.div>

    <div className='slider'>
      <Slider {...settings}>
        {  
          arr1.map(ele => { 
            return <motion.div className='slides'>
              <div className='insideSlide'>
                <div
                  className={`slidePhoto${ele.id}`}
                ></div>
                <div className='slideInside'>
                <h4>{ele.title}</h4>
                <p>{ele.content}</p>
                </div>
              </div>
            </motion.div>
          })
        }
      </Slider>
    </div>
    <div id='workDoneInClass'>
      <h1 style={{ marginBottom: "10px", marginTop: "50px" }}>Work Done By Students In Class</h1>
      <div
        className='Margine'
      style={{ backgroundColor: "darkorange",
       height: "2px",
        margin: "auto", 
        marginBottom: "40px", 
        width: "44%" }}></div>
         <div
        className='phoneMargine'
      style={{ backgroundColor: "darkorange",
       height: "2px",
        margin: "auto", 
        marginBottom: "40px", 
        width: "90%" }}></div>

    <div style={{margin:"auto"}}>
        <div className='row'>
              <motion.div 
                className='studentMain'
                whileHover={{scale:1.2}}
                transition={{duration:2}}
                ></motion.div>         
        </div>

    <div className='row' 
    style={{marginBottom:"20px"}}>
      <div className='col studentDiv'>
        <motion.div 
        className='studentPhoto1'
        whileHover={{scale:1.2}}
        transition={{duration:1}}
        >
        </motion.div>
      </div>
      <div className='col studentDiv'>
        <motion.div 
        className='studentPhoto5'
        whileHover={{scale:1.2}}
        transition={{duration:1}}
        >
        </motion.div>
      </div>
      <div className='col studentDiv'>
        <motion.div 
        className='studentPhoto2'
        whileHover={{scale:1.2}}
        transition={{duration:1}}
        >
        </motion.div>
      </div>
    </div>

    <div className='row'>
      <div className='col studentDiv'>
        <motion.div 
        className='studentPhoto3'
        whileHover={{scale:1.2}}
        transition={{duration:1}}
        >
        </motion.div>
      </div>
      <div className='col studentDiv'>
        <motion.div 
        className='studentPhoto4'
        whileHover={{scale:1.2}}
        transition={{duration:1}}
        >
        </motion.div>
      </div>
    </div>

    <div className='row'>
      <div className='col studentDiv'>
        <motion.div 
        className='studentPhoto7'
        whileHover={{scale:1.2}}
        transition={{duration:1}}
        >
        </motion.div>
      </div>
      <div className='col studentDiv'>
        <motion.div 
        className='studentPhoto8'
        whileHover={{scale:1.2}}
        transition={{duration:1}}
        >
        </motion.div> 
      </div>
      <div className='col studentDiv'>
        <motion.div 
        className='studentPhoto9'
        whileHover={{scale:1.2}}
        transition={{duration:1}}
        >
        </motion.div>
      </div>
    </div>

     </div>   
      <div className='numberOfStudentsTrained'>
     <h1 >
        More than </h1>
        <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(true)}>
      <h1>
        {counterOn &&
        <CountUp start={0} end={1500} duration={2} delay={0}/>
        }
        </h1>
        </ScrollTrigger>
      <h1> Students Trained </h1>
      </div>

    <div class="custom-shape-divider-bottom-1716299990">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
      </svg>
    </div>
</div>
  </div>)
}
export default FourthSection;